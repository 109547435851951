<template>
  <div class="managerBorder">

    <div>Horarios</div>

    <v-container>

      <!-- Table -->
      <v-row v-if="schedules.length > 0">
        <v-col cols="12">

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"> Día </th>
                  <th class="text-left"> Hora </th>
                  <th class="text-left"> Acciones </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) in schedules"
                  :key="idx"
                >
                  <td>{{ item.day }}</td>
                  <td>{{ item.start }}</td>
                  <td align="center">
                    <v-icon small class="mr-2" color="error" @click="removeSchedule(item)">mdi-close</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-col>
      </v-row>

      <v-row v-else>
        <v-col align="center">
          <v-icon>mdi-information-outline</v-icon>
          <p>No hay horarios para este taller</p>
        </v-col>
      </v-row>

    </v-container>

    <v-divider></v-divider>

    <!-- New schedule -->
    <v-row>
      <v-col class="pt-6">

        <div class="mb-2">Agregar un horario</div>

        <v-toolbar dense floating >

          <v-select
            v-model="newSch.day"
            placeholder="Día"
            :items="days"
            hide-details
            single-line
            dense
          />

          <v-menu
            ref="menu"
            v-model="menuPicker"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="newSch.start"
            transition="scale-transition"
            offset-y
            min-width="280px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newSch.start"
                label="Hora"
                hide-details
                single-line
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menuPicker"
              v-model="newSch.start"
              format="24hr"
              full-width
              @click:minute="$refs.menu.save(newSch.start)"
            ></v-time-picker>
          </v-menu>

          <v-btn icon color="success">
            <v-icon @click="addSchedule()">mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: 'ManageSchedules',
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    schedules: {
      handler(newVal) {
        this.internalSch = newVal
      },
      deep: true,
    },
  },
  data() {
    return {
      //state
      menuPicker: false,
      //data
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      //form
      internalSch: [],
      newSch: {
        day: null,
        start: null,
      },
    }
  },
  methods: {
    addSchedule() {
      if(this.newSch.day && this.newSch.start) {

        this.internalSch.push(this.newSch)
        this.$emit('updateSchedule', this.internalSch)

        this.newSch = { day: null, start: null, }
      }
    },
    removeSchedule(sch) {
      this.internalSch = this.internalSch.filter(item => JSON.stringify(item) !== JSON.stringify(sch))
      this.$emit('updateSchedule', this.internalSch)
    },
    closeDialog() { this.show = false }
  }
}
</script>

<style lang="scss" scoped>
.managerBorder {
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 5px;
  padding: 5px;
}
</style>