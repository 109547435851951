<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Nuevo Taller</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newCourse.name"
                  label="Nombre*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="newCourse.professor"
                  :items="professors"
                  item-text="name"
                  item-value="_id"
                  label="Profesor"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newCourse.level"
                  label="Nivel*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newCourse.levelExplanation"
                  label="Descripción del nivel"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newCourse.shortDescription"
                  label="Descripción corta*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="newCourse.description"
                  label="Descripción*"
                  hint="Escriba una descripción del taller"
                  outlined dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ManageImages :loadedURLS="newCourse.courseImg" @updateImages="setImages" />
              </v-col>
              <v-col cols="6">
                <ManageSchedules :schedules="newCourse.schedules" @updateSchedule="setSchedules" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="newCourse.isEnabled"
                  label="¿Es visible para el público?"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveCourse()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import ManageSchedules from "@/components/admin/ManageSchedules"
import ManageImages from "@/components/admin/ManageImages"
import { convertURLtoFile } from "@/libs/fileHelpers"

export default {
  name: "CreateCourseDialog",
  components: {
    ManageSchedules,
    ManageImages
  },
  props:{
    value: Boolean
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //data
      professors: [],
      //form
      newCourse: {
        name: '',
        professor: null,
        level: '',
        levelExplanation: '',
        shortDescription: '',
        description: '',
        courseImg: [],
        schedules: [],
        isEnabled: true,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) this.loadProfessors()
    }
  },
  methods: {
    async saveCourse() {
      try {

        if (!this.newCourse.name || !this.newCourse.professor || !this.newCourse.level || !this.newCourse.shortDescription || !this.newCourse.description || this.newCourse.courseImg.length < 1 ) return this.invalidFormNotification()

        this.isSaving = true

        let fd = new FormData()
        fd.append('name', this.newCourse.name)
        fd.append('professor', this.newCourse.professor)
        fd.append('level', this.newCourse.level)
        fd.append('levelExplanation', (this.newCourse.levelExplanation) ? this.newCourse.levelExplanation : null)
        fd.append('shortDescription', this.newCourse.shortDescription)
        fd.append('description', this.newCourse.description)
        fd.append('schedules', JSON.stringify(this.newCourse.schedules))
        fd.append('isEnabled', this.newCourse.isEnabled)

        for(let i = 0; i < this.newCourse.courseImg.length; i++) {
          const file = await convertURLtoFile(this.newCourse.courseImg[i])
          fd.append('courseImg', file)
        }

        await this.axios.post(`${process.env.VUE_APP_APIURL}/course`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Taller guardado'
        this.isSnackbar = true

        this.show = false

        this.newCourse = {
          name: '',
          professor: null,
          level: '',
          levelExplanation: '',
          shortDescription: '',
          description: '',
          courseImg: [],
          schedules: [],
          isEnabled: true,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al guardar el taller'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    async loadProfessors() {
      try {

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/professor`)
        this.professors = response.data.data

      } catch (e) {
        console.error(e)
      }
    },
    setSchedules(schArray) {
      this.newCourse.schedules = schArray
    },
    setImages(images) {
      this.newCourse.courseImg = images
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos requeridos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  },
}
</script>