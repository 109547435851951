<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Editar Taller</span>
        </v-card-title>

        <v-card-text v-if="!isLoading">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editCourse.name"
                  label="Nombre*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="editCourse.professor"
                  :items="professors"
                  item-text="name"
                  item-value="_id"
                  label="Profesor"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editCourse.level"
                  label="Nivel*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editCourse.levelExplanation"
                  label="Descripción del nivel"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editCourse.shortDescription"
                  label="Descripción corta*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="editCourse.description"
                  label="Descripción*"
                  hint="Escriba una descripción del taller"
                  outlined dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ManageImages :loadedURLS="editCourse.courseImg" @updateImages="setImages" />
              </v-col>
              <v-col cols="6">
                <ManageSchedules :schedules="editCourse.schedules" @updateSchedule="setSchedules" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="editCourse.isEnabled"
                  label="¿Es visible para el público?"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <LoadingIndicator v-else />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveEditCourse()" :disabled="isSaving || isLoading" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import ManageSchedules from "@/components/admin/ManageSchedules"
import ManageImages from "@/components/admin/ManageImages"
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import { convertURLtoFile } from "@/libs/fileHelpers"

export default {
  name: "EditCourseDialog",
  components: {
    ManageSchedules,
    ManageImages,
    LoadingIndicator,
  },
  props:{
    value: Boolean,
    fetchId: String,
  },
  data() {
    return {
      //state
      isLoading: true,
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //data
      professors: [],
      //form
      editCourse: {
        name: '',
        professor: null,
        level: '',
        levelExplanation: '',
        shortDescription: '',
        description: '',
        courseImg: [],
        schedules: [],
        isEnabled: true,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) {
        this.loadCourse()
        this.loadProfessors()
      }
    }
  },
  methods: {
    async loadCourse() {
      try {

        this.isLoading = true

        if(!this.fetchId) throw 'Missing id, cannot load info'

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/course/id/${this.fetchId}`)

        if(response.data.data) {

          let courseImg = {}

          if(Array.isArray(response.data.data.imgURL)) {
            const imagesURLS = response.data.data.imgURL.map(img => `${process.env.VUE_APP_APIURL}/uploads/${img}`)
            courseImg = { courseImg: imagesURLS }
          }

          this.editCourse = {
            ...this.editCourse, 
            ...response.data.data, 
            ...courseImg,
            professor: response.data.data.professor._id,
          }
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar el taller'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    async saveEditCourse() {
      try {

        if(!this.fetchId) return console.error('Missing id, cannot edit')

        if (!this.editCourse.name || !this.editCourse.professor || !this.editCourse.level || !this.editCourse.shortDescription || !this.editCourse.description || this.editCourse.courseImg.length < 1 ) return this.invalidFormNotification()

        this.isSaving = true

        let fd = new FormData()
        fd.append('name', this.editCourse.name)
        fd.append('professor', this.editCourse.professor)
        fd.append('level', this.editCourse.level)
        fd.append('levelExplanation', (this.editCourse.levelExplanation) ? this.editCourse.levelExplanation : null)
        fd.append('shortDescription', this.editCourse.shortDescription)
        fd.append('description', this.editCourse.description)
        fd.append('schedules', JSON.stringify(this.editCourse.schedules))
        fd.append('isEnabled', this.editCourse.isEnabled)

        for(let i = 0; i < this.editCourse.courseImg.length; i++) {
          const file = await convertURLtoFile(this.editCourse.courseImg[i])
          fd.append('courseImg', file)
        }

        await this.axios.put(`${process.env.VUE_APP_APIURL}/course/id/${this.fetchId}`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Taller editado'
        this.isSnackbar = true

        this.show = false

        this.editCourse = {
          name: '',
          professor: null,
          level: '',
          levelExplanation: '',
          shortDescription: '',
          description: '',
          courseImg: [],
          schedules: [],
          isEnabled: true,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al editar el taller'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    async loadProfessors() {
      try {

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/professor`)
        this.professors = response.data.data

      } catch (e) {
        console.error(e)
      }
    },
    setSchedules(schArray) {
      this.editCourse.schedules = schArray
    },
    setImages(images) {
      this.editCourse.courseImg = images
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos requeridos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  },
}
</script>